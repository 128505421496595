<template>
  <div>
    <FormulaBanner :title="title" :formulaCode="formulaCode"/>
    <div class="index">
      <!-- 表单 -->
      <div>
        <div class="top_nav"><span></span>{{title}}
        </div>
        <div class="formBody">
          <!-- 表单 -->
          <MyPatient :memberId="memberId" :selectValue="selectValue" @change="userChange"/>
          <div style="padding: 10px 0px 0 0px;">
            <div class="vantBox">
              <div class="vanTabsFont">头和颈</div>
              <van-tabs class="vantTab" type="card" v-model="activeName" @click="change">
                <van-tab :title="item" :name="item" v-for="item in List" :key="item" ></van-tab>
              </van-tabs>
            </div>
            <div class="vantBox">
              <div class="vanTabsFont">脸</div>
              <van-tabs class="vantTab" type="card" v-model="active2Name" @click="change">
                <van-tab :title="item" :name="item" v-for="item in List" :key="item"></van-tab>
              </van-tabs>
            </div>
            <div class="vantBox">
              <div class="vanTabsFont">胸</div>
              <van-tabs class="vantTab" type="card" v-model="active3Name" @click="change">
                <van-tab :title="item" :name="item" v-for="item in List" :key="item"></van-tab>
              </van-tabs>
            </div>
            <div class="vantBox">
              <div class="vanTabsFont">腹</div>
              <van-tabs class="vantTab" type="card" v-model="active4Name" @click="change">
                <van-tab :title="item" :name="item" v-for="item in List" :key="item"></van-tab>
              </van-tabs>
            </div>
            <div class="vantBox">
              <div class="vanTabsFont">肢端</div>
              <van-tabs class="vantTab" type="card" v-model="active5Name" @click="change">
                <van-tab :title="item" :name="item" v-for="item in List" :key="item"></van-tab>
              </van-tabs>
            </div>
            <div class="vantBox">
              <div class="vanTabsFont">外部</div>
              <van-tabs class="vantTab" type="card" v-model="active6Name" @click="change">
                <van-tab :title="item" :name="item" v-for="item in List" :key="item"></van-tab>
              </van-tabs>
            </div>
          </div>
          <div v-if="compute === true" style="margin: 16px;display: flex;justify-content: center;">
            <van-button class="formula-template-button" block type="info" @click="submit" style="width:90px">提交</van-button>
          </div>
          

          <!-- <div>
            <div class="RadioStyle">
              <div class="radioFont">头和颈</div>
              <div style="" class="radioBox">
                <div class="radioList">
                <input type="radio" @click="onClickItem('轻度')" name="Storage" id="Radio1" value="轻度" v-model="radioParam"/>
                <label for="Radio1">轻度</label>
                <input type="radio" name="Storage" id="Radio2"  value="中度" v-model="radioParam"/>
                <label for="Radio2">中度</label>
                <input type="radio" name="Storage" id="Radio3"  value="较重" v-model="radioParam"/>
                <label for="Radio3">较重</label>
                <input type="radio" name="Storage" id="Radio4"  value="重度" v-model="radioParam"/>
                <label for="Radio4">重度</label>
                <input type="radio" name="Storage" id="Radio5"  value="极重" v-model="radioParam"/>
                <label for="Radio5">极重</label>
                <input type="radio" name="Storage" id="Radio6"  value="致命" v-model="radioParam"/>
                <label for="Radio6">致命</label>

                <input type="radio" :id="item"  :value="item" v-model="radioParam" v-for="{item,index} in List" :key="index"/>
                <label :for="item"   @click="onClickItem(item)" v-for="item in List" :key="item">{{ item }}</label>
               </div> 
              </div>

            </div>
          </div> -->

          <!-- <div style="margin:16px 0;display: flex;justify-content: center;width:100%;box-sizing: border-box;">
            <van-button class="formula-template-button" block type="info" @click="submit">提交</van-button>
         </div> -->

          <!-- 计算结果 -->
          <!-- <div class="CalculationResultsBox" v-if="countText || countText !== ''">
            <div class="CalculationResults"><span>计算结果</span></div>
            <div>
              <div class="CalculationDetail"><span></span>{{countText}}</div>
            </div>
          </div> -->
        </div>
      </div>
      <!-- 计算结果 -->
      <CalculationResults :countText="countValue" :countTextList="countTextList" v-if="show"></CalculationResults>
      <!-- 后台设置的值 -->
      <FormulaExplain :explain-data="tableData.explain"></FormulaExplain>
      <!-- 参考文献 -->
      <FormulaReferences :references-data="tableData.references"></FormulaReferences>
    </div>

    <van-popup v-model="showPicker" position="bottom">
      <van-picker
          title="应激指数"
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="onCancel"
      />
    </van-popup>

  </div>

</template>

<script>
import CalculationResults from "@/components/CalculationResults";
import MyPatient from '@/components/MyPatient.vue'
import FormulaBanner from '@/components/FormulaBanner.vue'
import {Toast} from "vant";
import {getAdmins} from "@/utils/adminDate";
import {setById} from "@/service/base";
import {getFormula, getFormulaHuiXian, postFormula} from "@/service/BodyMassIndex_api";
import FormulaExplain from "@/components/template1/FormulaExplain";
import FormulaReferences from "@/components/template1/FormulaReferences";
export default {
  name: "medicalSearch",
  data(){
    return{
      compute:true,
      show:false,
      radioParam:'',
      checked:'',
      activeName:'',
      active2Name:'',
      active3Name:'',
      active4Name:'',
      active5Name:'',
      active6Name:'',
      listSelect: [],
      List:['','轻度','中度','较重','重度','极重','致命'],
      formulaCode: '',
      shengao: '',
      yingjizhishu: '',
      title: '',
      memberId: '1111',
      selectValue: '',
      username:"",
      value:"",
      showPopover: false,
      showPicker: false,
      columns: ['正常或轻伤', '中度创伤', '重度创伤'],
      nianling: '',
      xingbie: null,
      tableData: {},
      countText: '',
      countTextList:[],
      patientId: null,
      result: null,
      yingjizhishuzhi: '',
      tizhong: '',
      xueqingnanongdu: '',
      contentList:[],
      countValue:'',
    }
  },
  components: {
    FormulaBanner,
    MyPatient,
    FormulaExplain,
    FormulaReferences,
    CalculationResults
  },
  async created() {
    await this.test()
    await this.getTableData()
  },

  methods:{
    change(){
       this.show=false
    },
    onClickItem(value){
      console.log("数值",value)
    },
    listChange (result) {
      
      this.listSelect = result
    },
    coumputTetxt () {
      this.show=true
    //   this.result = this.listSelect.length
    //   if (this.result <= 2 && this.result >= 0) {
    //     this.countText = '低危患者'
    //   } else if (this.result >= 3) {
    //     this.countText = '高危患者'
    //   }
    //   this.result = this.countText
      this.contentList = [
        {
         "title": "头和颈", //分类
         "value": this.activeName //选中值
        },
        {
         "title": "脸", //分类
         "value": this.active2Name //选中值
        },
        {
         "title": "胸", //分类
         "value": this.active3Name //选中值
        },
        {
         "title": "腹", //分类
         "value": this.active4Name //选中值
        },
        {
         "title": "肢端", //分类
         "value": this.active5Name //选中值
        },
        {
         "title": "外部", //分类
         "value": this.active6Name //选中值
        }
      ]
      // 算分
      var fenshu1 = null
      this.List.forEach(item => {
        const index = this.List.indexOf(this.activeName);
        if(this.List.indexOf(this.activeName) >=0 ){
          fenshu1 = index
          item
        }
      })
      var fenshu2 = null
      this.List.forEach(item => {
        const index = this.List.indexOf(this.active2Name);
        if(this.List.indexOf(this.active2Name) >=0 ){
          fenshu2 = index
          item
        }
      })
      var fenshu3 = null
      this.List.forEach(item => {
        const index = this.List.indexOf(this.active3Name);
        if(this.List.indexOf(this.active3Name) >=0 ){
          fenshu3 = index
          item
        }
      })
      var fenshu4 = null
      this.List.forEach(item => {
        const index = this.List.indexOf(this.active4Name);
        if(this.List.indexOf(this.active4Name) >=0 ){
          fenshu4 = index
          item
        }
      })
      var fenshu5 = null
      this.List.forEach(item => {
        const index = this.List.indexOf(this.active5Name);
        if(this.List.indexOf(this.active5Name) >=0 ){
          fenshu5 = index
          item
        }
      })
      var fenshu6 = null
      this.List.forEach(item => {
        const index = this.List.indexOf(this.active6Name);
        if(this.List.indexOf(this.active6Name) >=0 ){
          fenshu6 = index
          item
        }
      })
      const fenshuList = []
      fenshuList.push(fenshu1,fenshu2,fenshu3,fenshu4,fenshu5,fenshu6)
      let nums = fenshuList;
      let res = nums.filter((num) => {
        return num == 0;
      });
      // 选项少于等于两项，但是有一个致命还是75分 
      let rules = nums.filter((num) => {
        return num == 6;
      });
      if(res.length >= 4 && rules.length>0){
        this.result = 75
        this.countText = "几乎濒临死亡"
        this.countValue = `${this.result}分 ${this.countText}`
      }else if(res.length >= 4 && rules.length == 0){
        this.countValue = "无效"
      }else{
        // 排序并算最大值
        let paixu =  fenshuList.sort()
        var defen = paixu[5] * paixu[5] + paixu[4] * paixu[4] + paixu[3] * paixu[3]
        // 如果有一项有6 ，得分就是75(选项大于两项但是有一个致命)
        // if(defen >= 75) defen = 75
        if(rules.length > 0) {
          defen = 75
        }
        this.result = defen 
        if(this.result <= 16 ){
          this.countText = "轻伤"
        }else if(this.result > 16  && this.result <= 25 ){
          this.countText = "重伤"
        }else if(this.result > 25  && this.result <= 50 ){   
          this.countText = "严重伤"
        }else if(this.result > 50){
          this.countText = "几乎濒临死亡"
        }          
        this.countValue = `${this.result}分 ${this.countText}`
      }
        
    },
    async test(){
      //像接口头部传用户信息
      let arrays = await getAdmins(this.$route.query.memberId,this.$route.query.channel)
      this.channel = arrays.os
      this.version = arrays.version
      this.ip = arrays.ip
      await setById(arrays)
    },
    async getTableData () {
      const data = {
        channel: this.$router.currentRoute.query.channel,
        code: 'BBVHJCUT',
      }
      const res = await getFormula(data.channel, data.code)
      if (res.code === 0) {
        this.tableData = res.data
        this.formulaCode = res.data.code
        this.title = res.data.chineseName
      }
      if (this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' && this.$router.currentRoute.query.logId) {
        // this.compute = false
        const data = {
          id: this.$router.currentRoute.query.logId
        }
        const res = await getFormulaHuiXian(data)
        if (res.code === 0) {
          this.contentList = res.data.content.value
          this.activeName = res.data.content.value[0].value
          this.active2Name = res.data.content.value[1].value
          this.active3Name = res.data.content.value[2].value
          this.active4Name = res.data.content.value[3].value
          this.active5Name = res.data.content.value[4].value
          this.active6Name = res.data.content.value[5].value
          if (res.data.patientId) this.selectValue = {id: res.data.patientId, name: res.data.patientName}
          this.countValue = `${res.data.result.value}分 ${res.data.result.result}`
          if(!res.data.result.value){
            this.countValue = '无效'
          }
          this.show=true
          // this.coumputTetxt()

        }
      }
    },
    async submit () {
      // if (!this.listSelect.length || this.listSelect.length === 0) {
      //   Toast.fail('请至少选择一项')
      //   return
      // }
      this.coumputTetxt()
      const data = {
        id: this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' ? this.$router.currentRoute.query.logId : null,
        patientId: this.patientId,
        columnCode: this.tableData.columnCode,
        formulaCode: this.tableData.code,
        channel: this.$router.currentRoute.query.channel,
        location: this.$router.currentRoute.query.location,
        content: {
          value: this.contentList
          // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
        },
        result: {
          value: this.result,
          result:this.countText,
          DisplayResults:this.countValue
        }
      }
      // console.log("数据",data)
      const res = await postFormula(data)
      if (res.code === 0) {
        Toast.success('提交成功')
      } else {
        Toast.fail(res.message)
      }
    },
    calculateAge(birthDateString) {
      const today = new Date();
      const birthDate = new Date(birthDateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      // 如果今天的日期还没到生日月份和日期，则年龄减一
      if (today.getMonth() < birthDate.getMonth() ||
          (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    // 开参考文献链接
    openLink(u){
      if (!u) {
        return
      }
      //获取系统版本
      let userAI = navigator.userAgent;
      const isAndroid = userAI.indexOf('Android') > -1 || userAI.indexOf('Linux') > -1; //Android终端
      const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (isAndroid) {
        // alert('安卓操作系统')
        // 这个是安卓操作系统
        window.open(u)
      }
      if (isIOS) {
        // 这个是ios操作系统
        // alert('ios操作系统')
        window.location.href = u
      }
    },
    yingjiClick () {
      this.showPicker = true
    },
    onConfirm(value) {
      this.yingjizhishu = value
      this.showPicker = false
    },
    onCancel() {
      this.showPicker = false
    },
    async onSubmit(){
      this.coumputTetxt()
      const data = {
        id: this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' ? this.$router.currentRoute.query.logId : null,
        patientId: this.patientId,
        columnCode: this.tableData.columnCode,
        formulaCode: this.tableData.code,
        channel: this.$router.currentRoute.query.channel,
        location: this.$router.currentRoute.query.location,
        content: {
          shengao: {value: this.shengao, unit: 'cm'},
          tizhong: {value: this.tizhong, unit: 'kg'},
          nianling: {value: this.nianling, unit: '岁'},
          xingbie: {value: this.xingbie == 1 ? '男' : '女'},
          // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
        },
        result: {}
      }
      if (this.xingbie == 1) {
        data.result.nanxing = {value: this.result, unit: 'kcal'}
      } else {
        data.result.nvxing = {value: this.result, unit: 'kcal'}
      }
      const res = await postFormula(data)
      if (res.code === 0) {
        Toast.success('提交成功')
      } else {
        Toast.fail(res.message)
      }
    },
    userChange (data) {
      if (data.birthday && data.birthday !== '') {
        this.nianling = this.calculateAge(data.birthday)
      } else {
        this.nianling = ''
      }
      this.xingbie = data.sex
      this.patientId = data.id
      this.selectValue = data
    },
  }
}
</script>


<style scoped lang="scss">
.index{
  background: #F6F6F6;
  padding: 10px 15px;
  height: 100%;
  .StandardDescription{
    margin-top:10px;
    .top_nav{
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom:10px;
      span {
        display: inline-block;
        width: 5px;
        height: 15px;
        background: #259beb;
        margin-right: 5px;
      }
    }
    .StandardDeatil{
      width:100%;
      padding:15px 10px;
      //   height: 50px;
      background-color: #fff;
      box-sizing: border-box;
    }
  }
  .top_nav{
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom:10px;
    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }
  .formBody{
    background-color: #fff;
    padding-bottom:10px;
    .CalculationResultsBox{
      padding:0 8px;
      .CalculationResults{
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom:10px
      }
      .CalculationDetail{
        font-size: 15px;
        color: #333333;
        // font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom:10px;
        background-color: #f6f6f6;
        // height: 16px;
        span{
          display: inline-block;
          width: 3px;
          height: 24px;
          background: #fe9712;
          margin-right: 5px;
        }
      }
    }
  }
  .titles{
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    height: 50px;
    border-bottom: 1px solid #f0f0f0;
    padding: 0 10px;
    box-sizing: border-box;
    .titlesName{
      font-size:16px;
      i{
        color: red;
      }
    }
    .information{
      font-size:16px;
      margin-right:4px;
      .PleaseSelect{
        color: #b9b9b9;
      }
      .vanCell{
        width:50px;
        padding:0;
        height: 100%;
      }
    }
    .vBotton{
      width:60px
    }
    .unit{
      height: 50px;
      //   line-height: 50px;
      display: flex;
      align-items: center;
      font-size:16px;
      width:20px;
    }
    .vanIcon{
      font-size:13px;
      color:#a8a8a8;
      font-weight: 650;
    }
  }
}
::v-deep .van-cell {
  padding:0px 0px;
}
::v-deep .van-field__control{
  height: 50px;
  font-size: 16px;
}
::v-deep .van-field__error-message {
  position: absolute;
  top: 30px;
  left: 10px;
}
.StandardDeatil2{
  width:100%;
  padding: 15px 10px;
  padding-bottom:1px;
  //   height: 50px;
  background-color: #fff;
  box-sizing: border-box;
  .references{
    margin-bottom:15px
  }
}
::v-deep p {
  padding: 0;
  margin: 0;
}

::v-deep .van-tabs__nav--card {
  margin: 0;
}
.vantTab{
  margin-top:10px;
  padding-bottom:10px;
  ::v-deep .van-tabs__nav--card{
    // border:0;
    // margin:0;
    .van-tab{
      color:#847d7d ;
    &:first-child{
      padding:0;
      margin: 0 !important;
      width:0;
      display: none !important;
    }

  }
  }

  // 
}
::v-deep .van-tabs--card>.van-tabs__wrap {
    height: 33px;
}
::v-deep .van-tabs__nav--card{
  border: 1px solid #eae9e9;
  border-radius:3px;
  height: 33px;
}
::v-deep .van-tabs__nav--card .van-tab {
    // color: #ee0a24;
    border-right: 1px solid #eae9e9;
}
::v-deep .van-tabs__nav--card .van-tab:last-child {
    // color: #ee0a24;
    border-right: 0px solid #eae9e9;
}
::v-deep.van-tabs__nav--card .van-tab.van-tab--active {
    color: #fff;
    background-color: #0191fb;
    border-top:1px solid #0191fb !important;
    border-bottom:1px solid #0191fb !important;
}
::v-deep .van-tab--active{
        color:#fff !important;
        background-color: #0191fb !important;
  }
.vanTabsFont{
  margin-top:10px
}
.vantBox{
  border-bottom: 1px solid #f0f0f0;
  padding:0 10px;
}
.radioList{
  display: flex;
  width:100%
}
.radioBox{
  width:100%;display:flex;justify-content: center; 
  // border-bottom: 1px solid #641515;
}
.RadioStyle{
  width:100%;
  padding:10px;
  box-sizing: border-box;
  border-bottom: 1px solid #f0f0f0;
  .radioFont{
    margin-bottom:10px
  }
}
.RadioStyle input {
        line-height: 30px;
        height: 30px;
        border-radius: 2px;
        display: none
    }
    .RadioStyle label {
        border: 1px solid #d2d2d2;
        border-right:0;
        line-height: 30px;
        height: 30px;
        font-size:14px;
        // padding: 0px 13px;
        float: left;
        border-radius: 2px;
        // margin: 5px 8px;
        text-align: center;
        width:16.66%
    }
    .RadioStyle label:last-child {
        border: 1px solid #d2d2d2;
        // border-left:0;
        line-height: 30px;
        height: 30px;
        font-size:14px;
        // padding: 0px 13px;
        float: left;
        border-radius: 2px;
        // margin: 5px 8px;
        text-align: center;
        width:16.66%
    }
    .RadioStyle input:checked + label {
        // border: 1px solid #1891fc;
        background-color: #1891fc;
        color: #FFf
    }
</style>
